<template>
  <v-slider
    ref="sliderRef"
    v-model="value"
    class="vc-setting-number-slider align-center"
    :min="min"
    :max="max"
    :step="1"
    :tick-size="tickSize"
    :ticks="ticks"
    color="primary"
    show-ticks="always"
    thumb-label
    hide-details
    @update:focused="onUpdateFocused"
  >
    <template #prepend>
      <v-btn
        class="vc-setting-number-slider__button"
        variant="tonal"
        icon="mdi-minus"
        color="primary"
        elevation="4"
        @click="decrement"
      />
    </template>
    <template #append>
      <v-btn
        class="vc-setting-number-slider__button"
        variant="tonal"
        icon="mdi-plus"
        color="primary"
        elevation="4"
        @click="increment"
      />
    </template>
  </v-slider>
</template>

<script setup lang="ts">
import { computed, ref, watch, toRefs } from "vue";
import { VSlider } from "vuetify/components";
import { useClamp, useDebounce } from "@/composable/useLodash";

const sliderRef = ref<InstanceType<typeof VSlider>>();

const props = defineProps<{
  modelValue: number;
  percent: boolean;
  initialValue?: number;
  min: number;
  max: number;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: number): void;
}>();

const focus = ref(true);
const _value = ref(props.modelValue);
const value = computed({
  get(): number {
    return focus.value ? _value.value : props.modelValue;
  },
  set(newValue: number) {
    _value.value = newValue;
  },
});
const tickSize = computed(() => (props.initialValue ? 3 : 2));
const ticks = computed(() => {
  const prefix = props.percent ? "%" : "";
  return Object.fromEntries(
    [props.min, props.initialValue, props.max].filter((x) => x === 0 || x).map((x) => [x, `${x}${prefix}`]),
  ) as Record<number, string>;
});
const decrement = () => emit("update:modelValue", useClamp(props.modelValue - 1, props.min, props.max));
const increment = () => emit("update:modelValue", useClamp(props.modelValue + 1, props.min, props.max));

let handleTouchEnd: () => void;
let handleTouchCancel: () => void;
const createHandleTouchFinish = (key: string) =>
  useDebounce(() => {
    setTimeout(() => {
      if (focus.value) {
        sliderRef.value?.$el.querySelector(".v-slider-thumb").blur();
      }
      if (key !== "touchcancel") {
        document.body.removeEventListener("mouseup", handleTouchEnd);
        document.body.removeEventListener("touchend", handleTouchEnd);
      }
      document.body.removeEventListener("touchcancel", handleTouchCancel);
      emit("update:modelValue", useClamp(_value.value, props.min, props.max));
    }, 100);
  }, 100);
handleTouchEnd = createHandleTouchFinish("touchend");
handleTouchCancel = createHandleTouchFinish("touchcancel");

const onUpdateFocused = (newValue: boolean) => {
  focus.value = newValue;
  if (newValue) {
    document.body.addEventListener("mouseup", handleTouchEnd);
    document.body.addEventListener("touchend", handleTouchEnd);
    document.body.addEventListener("touchcancel", handleTouchCancel);
  }
};

const { modelValue } = toRefs(props);
watch(modelValue, () => {
  _value.value = modelValue.value;
});
</script>

<style lang="scss">
.vc-setting-number-slider {
  flex: 0 400px auto;
  max-width: 300px;
  min-width: 300px;

  .is-mobile & {
    flex: 0 auto auto;
    max-width: none;
    min-width: auto;
  }
}

.v-slider-track__tick-label {
  .vc-setting-number-slider & {
    font-size: 10px;
  }
}

.vc-setting-number-slider__button {
  font-size: 12px;
}
</style>
