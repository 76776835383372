<template>
  <v-expansion-panels v-if="mobile" v-model="isOpen" class="mb-4">
    <v-expansion-panel :hide-actions="!openable">
      <v-expansion-panel-title class="d-flex align-center">
        <v-label class="vc-setting__label" :class="labelClass" :text="label" />
        <v-icon v-if="onlySubscribed" class="mr-1 mt-1" icon="mdi-lightning-bolt" color="boost" />
        <v-spacer />
        <slot name="display" />
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <slot name="mobile" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <template v-else>
    <slot name="pc" />
  </template>
</template>

<script setup lang="ts">
import { nextTick, ref, computed } from "vue";
import { useDisplay } from "vuetify";
import { useVGuildStore } from "@/stores/useVGuildStore";

const { mobile } = useDisplay();

const vGuildStore = useVGuildStore();

const props = withDefaults(
  defineProps<{
    openable?: boolean;
    onlySubscribed?: boolean;
    label: string;
    focus?: string;
  }>(),
  {
    openable: false,
    onlySubscribed: false,
    focus: "",
  },
);

const _isOpen = ref<number | undefined>();

const isOpen = computed({
  get() {
    return _isOpen.value;
  },
  set(newValue: number | undefined) {
    if (!props.openable) {
      return;
    }

    _isOpen.value = newValue;
    if (newValue === 0) {
      nextTick(() => {
        document.getElementById(props.focus)?.focus();
      });
    }
  },
});

const labelClass = computed(() => ({
  "text-boost": props.onlySubscribed && vGuildStore.isSubscribed,
  "text-inactive": props.onlySubscribed && !vGuildStore.isSubscribed,
}));
</script>

<style lang="scss">
.vc-setting__label {
  min-width: 80px;
  opacity: 1;
}
</style>
