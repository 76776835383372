<template>
  <VcGuildInvite v-if="guild?.status === 'managed_not_joined'" />
  <div v-if="vGuild && vGuild.id !== '0'" class="p-boost">
    <div class="p-boost__container d-flex flex-column align-center pa-1">
      <VcPlans>
        <div class="w-75 mx-auto pa-2 mb-6">
          <VcGuildHeadline class="mb-2" :guild="guild" :boost-count="boostCount" />
          <VcSpeechCountProgressBar
            :current-speech-text-count="vGuild.speech_text_count"
            :max-speech-text-count="maxSpeechTextCount"
            :width="400"
          />
        </div>
        <div class="d-flex flex-wrap gap-2 justify-center mb-6">
          <VcBoostDialog :guild-id="vGuild.guild_id" @update="onUpdateBoosters">
            <template #default="{ props }">
              <v-btn v-bind="props" color="boost" size="large">このサーバーをブーストする</v-btn>
            </template>
          </VcBoostDialog>
          <v-btn class="mb-6" color="boost" size="large" variant="outlined" @click="moveToDetails"
            >特典を比較する</v-btn
          >
        </div>
        <div v-if="mobile && boosterByCount && boosterByCount.length != 0" class="ma-8">
          <div class="text-h4 text-center w-100 font-weight-bold mb-6 mt-10">ブーストメンバー</div>
          <v-card
            v-for="([boostersBoostCount, boosterList], index) in boosterByCount"
            :key="boostersBoostCount"
            class="mb-2"
          >
            <v-card-title style="background-color: #121315">
              <v-icon :color="crownColor(index)">mdi-crown</v-icon>
              <v-icon v-for="i in Number(boostersBoostCount)" :key="i" :size="18" class="mr-n1" color="boost">
                mdi-lightning-bolt
              </v-icon>
            </v-card-title>
            <div class="d-flex" style="flex-wrap: wrap">
              <div v-for="booster in boosterList" :key="booster.id" class="ma-3 d-flex">
                <v-avatar color="black" size="32">
                  <v-img :src="userIcon(booster)" width="32" />
                </v-avatar>
                <div class="mx-1 text-caption">
                  <div class="">{{ booster.nick || booster.name }}</div>
                  <div class="text-grey">{{ booster.username }}</div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </VcPlans>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { User } from "@/types";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import VueScrollTo from "vue-scrollto";
import { useDisplay } from "vuetify";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useMenuStore } from "@/stores/useMenuStore";
import { userIcon } from "@/utilities/discord";

const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const snackbarStore = useSnackbarStore();
const menuStore = useMenuStore();
const { mobile } = useDisplay();

const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

const boostCount = computed(() => {
  return vGuild.value.boosters.reduce((sum, booster) => sum + (booster.boostCount || 0), 0);
});
const maxSpeechTextCount = computed(() => vGuildStore.boostCount * 100000 || 5000);

const crownColor = (rank: number): string => {
  switch (rank) {
    case 0:
      return "#ffd700FF";
    case 1:
      return "#F0F0F0FF";
    case 2:
      return "#ffa500FF";
    default:
      return "#12131500";
  }
};

const moveToDetails = () => {
  const duration = 200;
  const options = {
    easing: "ease-in",
    lazy: false,
    force: true,
    cancelable: false,
  };
  VueScrollTo.scrollTo("#details", duration, options);
};

const onUpdateBoosters = (boosters: User[]) => {
  vGuildStore.setVGuild({ ...vGuild.value, boosters: boosters });
  snackbarStore.addTopRight({
    color: "success",
    text: "ブーストしました！",
  });
  menuStore.setMenu(guild.value, vGuild.value);
};

const boosterByCount = computed(() => {
  const boosterByCount: Record<string, User[]> = {};
  vGuild?.value.boosters.forEach((booster) => {
    if (!booster.boostCount) return;

    boosterByCount[booster.boostCount.toString()] ||= [];
    boosterByCount[booster.boostCount.toString()].push(booster);
  });
  return Object.entries(boosterByCount).sort((x, y) => {
    return Number(y[0]) - Number(x[0]);
  });
});
</script>

<style lang="scss">
.p-boost {
  margin-top: -32px;
  padding-top: 64px;
  padding-bottom: 32px;

  .is-active .v-stepper-item__avatar {
    outline: 6px solid rgba(var(--v-theme-boost), 0.6);
  }
}

.p-boost__container {
  width: auto;
  margin: 16px auto;
}
</style>
