<template>
  <div class="ma-4">
    <div class="text-h3 text-center mb-10">コマンド一覧</div>

    <div class="text-h4 text-center mb-4">各コマンドの用語</div>
    <table>
      <tbody>
        <tr v-for="term in terms" :key="term.label">
          <td>
            {{ term.label }}
          </td>
          <td class="text-left">
            <span v-for="line in term.description.split('<br/>')" :key="line"> {{ line }}<br /> </span>
          </td>
        </tr>
      </tbody>
    </table>

    <template v-for="(group, groupIndex) in applicationCommands" :key="groupIndex">
      <div class="text-h4 mt-8">{{ group.kind }}</div>
      <v-expansion-panels>
        <v-expansion-panel v-for="(question, questionIndex) in group.options" :key="questionIndex">
          <v-expansion-panel-title>
            <div class="text-h6">{{ question.name }}</div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="mx-n3">
            <div v-for="(row, rowIndex) in question.description.split('<br/>')" :key="rowIndex" class="ml-4">
              {{ row }}<br />
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <div class="text-h5 text-center mb-15 mt-15 pb-15 pt-15">
      使い方を見ても分からない場合はサポートサーバーで質問することができます。
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const applicationCommands = ref([
  {
    kind: "BOT操作",
    options: [
      {
        name: "/join [channel]",
        description:
          "VOISCORDをボイスチャンネルに招待します。<br/><br/>【コマンド】<br/>channel: どのボイスチャンネルに招待するか指定できます。省略した場合はあなたが参加しているボイスチャンネルに参加します。<br/><br/>【ヒント】<br/>ボイスチャンネルにBOT以外のユーザーがいない場合はエラーが表示されます。",
      },
      {
        name: "/leave <name>",
        description:
          "VOISCORDをボイスチャンネルから切断します。<br/><br/>【コマンド】<br/>name: どのVOISCORDを切断するか選択します。",
      },
    ],
  },
  {
    kind: "一般設定",
    options: [
      {
        name: "/config list",
        description: "サーバー全体の設定を表示します。",
      },
      {
        name: "/config speaker <name>",
        description:
          "読み上げキャラクターを変更します。<br/><br/>【コマンド】<br/>name: キャラクターを選択します。<br/><br/>【ヒント】<br/>このコマンドで変更したキャラクターはサーバーのデフォルト話者になります。<br/>個人の話者を変更したい場合は個人設定項目をご覧下さい。",
      },
    ],
  },
  {
    kind: "辞書設定",
    options: [
      {
        name: "/dictionary add <before> <after> [global]",
        description:
          "辞書を追加・更新します。<br/><br/>【コマンド】<br/>before: 変換したい単語を入力します。<br/>after: 読み方を入力します。<br/>global: グローバル辞書登録の申請をします。Trueで申請し、Falseは申請しない。省略した場合は申請しません。<br/><br/>【ヒント】<br/>以下の条件に当てはまる単語は登録することができません。<br/>- 数値<br/>- 半角文字2文字未満<br/>- カスタムサーバー絵文字<br/><br/>【グローバル辞書申請】<br/>globalオプションでTrueを選択するとグローバル辞書申請が行えます。<br/>ただし、グローバル辞書申請では極端に短い文字や数値、カスタムサーバー絵文字などは申請することができません。",
      },
      {
        name: "/dictionary remove <before>",
        description:
          "単語を削除します。<br/><br/>【コマンド】<br/>before: 削除したい単語を入力します。<br/><br/>【ヒント】<br/>単語が登録されていない場合はエラーが表示されます。",
      },
    ],
  },
  {
    kind: "個人設定",
    options: [
      {
        name: "/information",
        description: "メンバーの個人設定を表示します。",
      },
      {
        name: "/speaker set <name>",
        description:
          "メンバーの読み上げキャラクターを変更します。<br/><br/>【コマンド】<br/>name: キャラクター名を選択します。",
      },
      {
        name: "/speaker clear",
        description: "メンバーの読み上げキャラクターをサーバー設定と同期させます。",
      },
    ],
  },
  {
    kind: "その他",
    options: [
      {
        name: "s (メッセージ読み上げスキップ機能)",
        description:
          "半角の「s」や全角の「ｓ」単体でメッセージを投稿すると、現在キューに追加されているメッセージをすべて読み飛ばすことができます。",
      },
      {
        name: "; (メッセージ対象外プレフィクス機能)",
        description: "メッセージの冒頭に「;」を付けると読み上げ対象から除外することができます。",
      },
    ],
  },
]);

const terms = [
  {
    label: "/command sub",
    description:
      "/ から始まる入力をコマンドやスラッシュコマンドと呼びます。<br/>コマンドを入力し、実行することでVOISCORDを操作することができます。<br/>例：/config list、/dictionary add",
  },
  {
    label: "<required>",
    description:
      "< >で囲まれている入力は必須引数やリクワイアドと呼びます。<br/>必須引数は省略することのできない必須の入力です。<br/>例：/leave <name>",
  },
  {
    label: "[option]",
    description:
      "[ ]で囲まれている入力はオプションと呼びます。<br/>オプションは省略することができます。<br/>例：/join [channel]",
  },
];
</script>

<style scoped>
.commands-card {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style: none;
}

.commands-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.commands-card-header h3 {
  margin: 0;
  color: #333;
}

.commands-arrow-icon {
  font-size: 16px;
  transition: transform 0.3s;
  color: #333;
}

.commands-arrow-rotate {
  transform: rotate(180deg);
}

.commands-card-content {
  padding: 10px;
}

table {
  border-collapse: collapse;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

td {
  border: 1px solid #ccc;
  padding: 8px;
}
</style>
