import type { Guild, VGuild } from "@/types";
import { defineStore } from "pinia";

interface Menu {
  title: string;
  to: string;
  icon: string;
  name: string;
  color?: string;
  if?: () => boolean;
}

export const useMenuStore = defineStore("menus", {
  state: () => {
    return {
      menus: [] as Menu[],
    };
  },

  actions: {
    async setMenu(guild?: Guild, vGuild?: VGuild, mobile: boolean = false) {
      if (!guild) {
        this.menus = [
          {
            title: "サーバーを選択",
            icon: "mdi-home",
            to: "/dashboard",
            name: "dashboard",
          },
          {
            title: "決済履歴",
            icon: "mdi-credit-card-clock-outline",
            to: "/payments",
            name: "payments",
          },
          {
            title: "ブーストを管理",
            icon: "mdi-lightning-bolt",
            to: "/boost",
            name: "boostManagement",
            color: "boost",
          },
        ];
      } else {
        const manageble = guild.status === "managed_joined" || vGuild?.is_moderator || false;
        const boostCount = vGuild?.boosters?.reduce((sum, booster) => sum + (booster.boostCount || 0), 0) || 0;

        this.menus = [
          {
            title: "ページ一覧",
            icon: "mdi-menu",
            to: `/server/${guild.id}/list`,
            name: "list",
            if: () => mobile,
          },
          {
            title: "利用統計",
            icon: "mdi-chart-areaspline",
            to: `/server/${guild.id}/statistics`,
            name: "statistics",
            if: () => manageble,
          },
          {
            title: "一般設定",
            icon: "mdi-cog",
            to: `/server/${guild.id}/general`,
            name: "general",
            if: () => manageble,
          },
          {
            title: "メンバー設定",
            icon: "mdi-account-multiple",
            to: `/server/${guild.id}/member`,
            name: "member",
          },
          {
            title: "チャンネル設定",
            icon: "mdi-format-list-bulleted",
            to: `/server/${guild.id}/channel`,
            name: "channel",
            if: () => manageble,
          },
          {
            title: "辞書設定",
            icon: "mdi-furigana-horizontal",
            to: `/server/${guild.id}/dictionary`,
            name: "dictionary",
            if: () => manageble,
          },
          {
            title: "モデレーター設定",
            icon: "mdi-wrench",
            to: `/server/${guild.id}/moderator`,
            name: "moderator",
            if: () => manageble,
          },
          {
            title: "追加BOTの招待",
            icon: "mdi-account-multiple-plus",
            to: `/server/${guild.id}/bots`,
            name: "bots",
            color: "boost",
            if: () => guild.status === "managed_joined" && boostCount >= 3,
          },
          {
            title: "ブースト",
            icon: "mdi-lightning-bolt",
            to: `/server/${guild.id}/boost`,
            name: "boost",
            color: "boost",
          },
          {
            title: "サーバー選択に戻る",
            icon: "mdi-arrow-left-bottom",
            to: "/dashboard",
            name: "dashboard",
            color: "surface-lighten",
            if: () => mobile,
          },
        ].filter((menu) => !menu.if || menu.if());
      }
    },
    unsetMenu() {
      this.menus = [];
    },
  },
});
