<template>
  <VcSettingBase :openable="true" :only-subscribed="onlySubscribed" :label="label" :focus="id">
    <template #pc>
      <v-card class="vc-setting" flat>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <v-icon v-if="onlySubscribed" class="mr-1" icon="mdi-lightning-bolt" color="boost" />
            <v-label class="vc-setting__label" :text="label" />
            <v-spacer />
            <VcSettingNumberSlider
              v-model="value"
              :percent="percent"
              :initial-value="initialValue"
              :min="min"
              :max="max"
              :disabled="disabled"
            />
            <VcSettingNumberInput v-model="value" :id="id" :min="min" :max="max" :disabled="disabled" />
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template #display>
      <v-chip class="mr-2"> {{ value }} </v-chip>
    </template>
    <template #mobile>
      <div class="d-flex">
        <VcSettingNumberSlider
          v-model="value"
          :percent="percent"
          :initial-value="initialValue"
          :min="min"
          :max="max"
          :disabled="disabled"
        />
        <VcSettingNumberInput :id="id" v-model="value" class="ml-4" :min="min" :max="max" :disabled="disabled" />
      </div>
    </template>
  </VcSettingBase>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useClamp } from "@/composable/useLodash";
import { useVGuildStore } from "@/stores/useVGuildStore";

const props = defineProps<{
  id: string;
  percent?: boolean;
  modelValue: number;
  label: string;
  initialValue: number;
  min: number;
  max: number;
  onlySubscribed?: boolean;
  requiredBoostCount?: number;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: number): void;
}>();

const vGuildStore = useVGuildStore();
const disabled = computed(() => {
  return props.disabled || (props.requiredBoostCount && props.requiredBoostCount > vGuildStore.boostCount);
});

const value = computed({
  get(): number {
    return disabled.value ? props.initialValue : props.modelValue;
  },
  set(newValue: number) {
    emit("update:modelValue", useClamp(newValue, props.min, props.max));
  },
});
</script>
