<template>
  <VcGuildInvite v-if="guild?.status === 'managed_not_joined'" />
  <div v-else-if="showSettings && vGuild.id !== '0'" class="pa-2">
    <div class="mx-auto pa-2 px-6">
      <VcGuildHeadline class="mb-2" :guild="guild" :boost-count="boostCount" />
      <VcSpeechCountProgressBar
        :current-speech-text-count="vGuild.speech_text_count"
        :max-speech-text-count="maxSpeechTextCount"
        :width="400"
      />
    </div>
    <div class="d-flex flex-column px-8 gap-1">
      <v-list-item
        v-for="menu in displayMenus"
        :key="menu.title"
        :title="menu.title"
        :to="menu.to"
        :prepend-icon="menu.icon"
        :color="menu.color"
        :base-color="menu.color"
        append-icon="mdi-chevron-triple-right"
        class="bg-surface pa-3 mb-2 font-weight-bold text-h6"
        rounded
        ripple
      />
      <v-list-item
        class="bg-surface-lighten-1 pa-3 mb-2 font-weight-bold text-h6"
        title="サーバー選択に戻る"
        to="/dashboard"
        append-icon="mdi-arrow-left-bottom"
        rounded
        ripple
      >
        <template #prepend>
          <v-img class="ml-1 mr-8 my-n2" :src="yukariUrl" height="40" width="22" />
        </template>
      </v-list-item>
    </div>
    <div v-if="mobile && boosterByCount && boosterByCount.length != 0" class="ma-8">
      <div class="text-h4 text-center w-100 font-weight-bold mb-6 mt-10">ブーストメンバー</div>
      <v-card
        v-for="([boostersBoostCount, boosterList], index) in boosterByCount"
        :key="boostersBoostCount"
        class="mb-2"
      >
        <v-card-title style="background-color: #121315">
          <v-icon :color="crownColor(index)">mdi-crown</v-icon>
          <v-icon v-for="i in Number(boostersBoostCount)" :key="i" :size="18" class="mr-n1" color="boost">
            mdi-lightning-bolt
          </v-icon>
        </v-card-title>
        <div class="d-flex" style="flex-wrap: wrap">
          <div v-for="booster in boosterList" :key="booster.id" class="ma-3 d-flex">
            <v-avatar color="black" size="32">
              <v-img :src="userIcon(booster)" width="32" />
            </v-avatar>
            <div class="mx-1 text-caption">
              <div class="">{{ booster.nick || booster.name }}</div>
              <div class="text-grey">{{ booster.username }}</div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else class="d-flex flex-column">
      <div class="text-h4 text-center w-100 font-weight-bold mb-6" style="margin-top: 60px">
        <div class="d-inline-block">みんなでサーバーをブーストして</div>
        <div class="d-inline-block">特典をアンロックしよう！</div>
      </div>
    </div>
    <div class="d-flex flex-column px-8">
      <v-btn class="mb-10" color="boost" size="large" @click="router.push(`/server/${guild?.id}/boost`)"
        >ブーストの特典内容を見る</v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { User } from "@/types";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import yukariUrl from "@/assets/images/yukari.png";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { useMenuStore } from "@/stores/useMenuStore";
import { userIcon } from "@/utilities/discord";

const { mobile } = useDisplay();
const router = useRouter();

const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const loadingStore = useLoadingStore();
const menuStore = useMenuStore();

const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);
const { menus } = storeToRefs(menuStore);

const showBots = ref(false);

const displayMenus = computed(() => {
  return menus.value.filter((menu) => {
    return !["ページ一覧", "サーバー選択に戻る"].includes(menu.title);
  });
});

const showSettings = computed(() => {
  return !guildStore.managedNotJoinedGuilds.map((guild) => guild.id).includes(vGuild.value.guild_id);
});

const boostCount = computed(() => {
  return vGuild.value.boosters.reduce((sum, booster) => sum + (booster.boostCount || 0), 0);
});

const maxSpeechTextCount = computed(() => vGuildStore.boostCount * 100000 || 5000);

watch(vGuild, () => {
  loadingStore.setIsGuildChangeLoading(false);
  showBots.value = guild?.value?.status === "managed_joined" && vGuildStore.boostCount >= 3;
});
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});

const boosterByCount = computed(() => {
  const boosterByCount: Record<string, User[]> = {};
  vGuild?.value.boosters.forEach((booster) => {
    if (!booster.boostCount) return;

    boosterByCount[booster.boostCount.toString()] ||= [];
    boosterByCount[booster.boostCount.toString()].push(booster);
  });
  return Object.entries(boosterByCount).sort((x, y) => {
    return Number(y[0]) - Number(x[0]);
  });
});

const crownColor = (rank: number): string => {
  switch (rank) {
    case 0:
      return "#ffd700FF";
    case 1:
      return "#F0F0F0FF";
    case 2:
      return "#ffa500FF";
    default:
      return "#12131500";
  }
};
</script>
