<template>
  <VcSettingBase :openable="true" :only-subscribed="false" :label="label">
    <template #pc>
      <v-card class="vc-setting" flat>
        <VcSettingAutoSaveChip v-if="!field" />
        <v-card-text>
          <div class="d-flex align-center">
            <v-label class="vc-setting__label mr-auto" :text="label" />
            <v-btn color="grey-darken-3" :append-icon="field ? 'mdi-menu-down' : 'mdi-menu-left'" @click="toggleField">
              詳細設定
            </v-btn>
          </div>
          <div v-if="!field" class="d-flex align-center">
            <v-label class="vc-setting__label ml-4 mr-auto" :text="dynamicFlgLabel" />
            <v-switch
              v-model="dynamicFlg"
              class="flex-grow-0"
              color="primary"
              hide-details
              inset
              :disabled="disabled"
            />
          </div>
          <div v-if="!field && useAutoSaveSection">
            <slot />
          </div>
          <div v-if="field" class="mt-4">
            <v-text-field
              v-model="messageFormat"
              variant="outlined"
              density="compact"
              hide-details
              single-line
              :disabled="disabled"
            />
            <div class="d-flex align-center flex-wrap mt-4 float-left" :class="{ 'mb-4': mobile }">
              <div class="d-inline-block mb-1">次のフラグを使用できます:</div>
              <div class="d-inline-block">
                <v-chip v-for="variable in basicVariables" :key="variable" class="ml-2" variant="outlined" size="small">
                  {{ variable }}
                </v-chip>
              </div>
            </div>
            <div class="mt-4 float-right">
              <v-btn
                class="mr-1 text-black"
                size="small"
                color="cancel"
                :disabled="disabled || !isChanged"
                @click="reset"
                >戻す</v-btn
              >
              <v-btn size="small" color="secondary" :disabled="disabled || !canSave" @click="save">保存</v-btn>
            </div>
          </div>
          <div class="clear" />
          <div v-if="field && useAutoSaveSection" class="position-relative">
            <VcSettingAutoSaveChip />
            <v-divider class="my-4" />
            <slot />
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template #mobile>
      <v-text-field
        v-model="messageFormat"
        variant="outlined"
        density="compact"
        hide-details
        single-line
        :disabled="disabled"
      />
      <div class="d-flex align-center flex-wrap mt-4" :class="{ 'mb-4': mobile }">
        <v-label class="vc-setting__label mb-1">フラグ:</v-label>
        <div class="d-inline-block">
          <v-chip v-for="variable in basicVariables" :key="variable" class="ml-2" variant="outlined">
            {{ variable }}
          </v-chip>
        </div>
      </div>
      <div class="d-flex align-center gap-1">
        <v-label class="vc-setting__label mb-1">プリセット:</v-label>
        <v-btn color="info" text="デフォルト" @click="messageFormat = dynamicFormatOff" />
        <v-btn color="info" :text="dynamicFlgLabel" @click="messageFormat = dynamicFormatOn" />
      </div>
      <div class="d-flex mt-8">
        <v-spacer />
        <v-btn class="mr-1 text-black" color="cancel" :disabled="disabled || !isChanged" @click="reset">戻す</v-btn>
        <v-btn color="secondary" :disabled="disabled || !canSave" @click="save">保存</v-btn>
      </div>
    </template>
  </VcSettingBase>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify";

const { mobile } = useDisplay();
const route = useRoute();

const props = defineProps<{
  modelValue: string;
  label: string;
  dynamicFlgLabel: string;
  dynamicFormatOn: string;
  dynamicFormatOff: string;
  dynamicFormatRegexp: RegExp;
  basicVariables: string[];
  disabled?: boolean;
  useAutoSaveSection?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: string): void;
}>();

const field = ref(false);

const initialize = () => {
  field.value = !(props.modelValue === props.dynamicFormatOn) && !(props.modelValue === props.dynamicFormatOff);
};
initialize();

const toggleField = () => (field.value = !field.value);

const calcDefaultFormat = (v: boolean): string => (v ? props.dynamicFormatOn : props.dynamicFormatOff);
const calcIsDynamicFlg = (s: string): boolean => props.dynamicFormatRegexp.test(s);

const initialValue = computed(() => {
  return props.modelValue;
});
const messageFormat = ref(initialValue.value);

watch(initialValue, () => {
  messageFormat.value = initialValue.value;
});
watch(route, initialize);

const isValid = computed(() => {
  const startParenCount = [...messageFormat.value.matchAll(/\{\{/g)].length;
  const endParenCount = [...messageFormat.value.matchAll(/\}\}/g)].length;
  if (startParenCount !== endParenCount) return false;

  const matches = messageFormat.value.matchAll(/\{\{(.*?)\}\}/g);
  const variables = Array.from(matches, (m) => m[1]);
  return variables.every((v) => props.basicVariables.includes(v));
});
const isChanged = computed(() => messageFormat.value !== initialValue.value);
const canSave = computed(() => isValid.value && isChanged.value);

const reset = () => {
  messageFormat.value = props.modelValue;
};
const save = () => {
  if (!isValid.value) return;
  if (!isChanged.value) return;

  emit("update:modelValue", messageFormat.value);
};
const dynamicFlg = computed({
  get(): boolean {
    return calcIsDynamicFlg(messageFormat.value);
  },
  set(newValue: boolean) {
    messageFormat.value = calcDefaultFormat(newValue);
    if (!field.value) {
      save();
    }
  },
});
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}
</style>
