<template>
  <v-list-item v-for="[boostCount, boosterList] in boosterByCount" :key="boostCount" class="px-0" density="compact">
    <div v-if="isSmall">
      <span v-for="booster in boosterList" :key="booster.id" class="relative mr-2">
        <v-avatar color="black" size="48">
          <v-img :src="userIcon(booster)" width="48" />
        </v-avatar>
        <v-chip
          variant="elevated"
          class="p-dashboard__booster__small-icon text-boost"
          density="compact"
          size="small"
          color="white"
        >
          <v-icon icon="mdi-lightning-bolt" /> x {{ Number(boostCount) }}
        </v-chip>
      </span>
    </div>
    <div v-else>
      <v-chip class="pr-4 mb-1" density="compact">
        <template v-if="Number(boostCount) <= 5">
          <v-icon v-for="i in Number(boostCount)" :key="i" class="mr-n1" color="boost" size="small"
            >mdi-lightning-bolt</v-icon
          >
        </template>
        <template v-else>
          <v-icon color="boost" size="small">mdi-lightning-bolt</v-icon> x {{ Number(boostCount) }}
        </template>
      </v-chip>
      <div v-for="booster in boosterList" :key="booster.id" class="d-flex ml-2">
        <v-avatar color="black" size="32">
          <v-img :src="userIcon(booster)" width="32" />
        </v-avatar>
        <div class="mx-1 text-caption">
          <div class="l-dashboard__booster-name">{{ booster.nick || booster.name }}</div>
          <div class="l-dashboard__booster-name text-grey">{{ booster.username }}</div>
        </div>
      </div>
    </div>
  </v-list-item>
</template>

<script setup lang="ts">
import type { User } from "@/types";
import { computed } from "vue";
import { userIcon } from "@/utilities/discord";

const props = withDefaults(
  defineProps<{
    boosters: User[];
    isSmall?: boolean;
  }>(),
  {
    isSmall: false,
  },
);

const boosterByCount = computed(() => {
  const boosterByCount: Record<string, User[]> = {};
  props.boosters.forEach((booster) => {
    if (!booster.boostCount) return;

    boosterByCount[booster.boostCount.toString()] ||= [];
    boosterByCount[booster.boostCount.toString()].push(booster);
  });
  return Object.entries(boosterByCount).sort((x, y) => {
    return Number(y[0]) - Number(x[0]);
  });
});
</script>

<style>
.p-dashboard__booster__small-icon {
  position: absolute;
  top: 25px;
  right: 5px;
  /* top: -10px; */
  /* right: -18px; */
  font-size: 10px !important;
  padding: 2px 8px 2px 2px !important;
  height: 12px !important;
}
</style>
