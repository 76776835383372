<template>
  <div class="tokusho-container">
    <h1 class="tokusho-title">特定商取引法に基づく表記</h1>
    <div class="tokusho-content">
      <table class="tokusho-table">
        <caption class="tokusho-caption mb-8">
          <p>*スパムメール防止のため、メールアドレスのアットマークを☆に置換しています。</p>
        </caption>

        <tbody v-for="(row, index) in dataList" :key="index" class="tokusho-tbody text-body-2">
          <tr>
            <td class="tokusho-td" style="text-align: right">{{ row[0] }}</td>
            <td class="tokusho-td">{{ row[1] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
const dataList = [
  ["販売業者", "HandyWeb Lab. 江間 俊太郎"],
  ["運営統括責任者", "江間 俊太郎"],
  ["郵便番号", "〒214-0003"],
  ["所在地", "神奈川県 川崎市 多摩区 菅稲田堤 2丁目 1番地 60号"],
  ["電話番号", "090-9237-0107"],
  ["メールアドレス", "contact☆voiscord.net"],
  ["引渡し時期", "お支払い手続き完了後、直ちにご利用いただけます。"],
  ["返品について", "サービスの性質上、返品・返金はお受けしておりません。"],
  ["支払方法", "■ クレジットカード"],
];
</script>

<style>
.tokusho-container {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.tokusho-title {
  margin: 30px 0;
}

.tokusho-content {
  display: flex;
  justify-content: center;
  font-weight: lighter;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  pointer-events: none;
}

.tokusho-caption {
  caption-side: bottom;
  text-align: left;
  font-size: 10px;
  padding-left: 12px;
  padding-top: 4px;
}

.tokusho-table {
  border-bottom: #ffffff solid 1px;
  width: 100%;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.tokusho-tbody {
  margin: 10px;
}

.tokusho-td {
  padding: 1px 8px;
  border: 0px none;
}
</style>
