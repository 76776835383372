<template>
  <VcGuildInvite v-if="guild?.status === 'managed_not_joined'" />
  <div
    v-if="vGuild && vGuild.id !== '0'"
    class="d-flex justify-center align-center flex-column pa-2 pt-10"
    :class="{ 'is-mobile': mobile }"
  >
    <div class="p-dashboard-statistics__statistics-container">
      <div class="text-h5 mx-auto mb-4">最近の読み上げ文字数</div>
      <div class="d-flex justify-center gap-2 flex-wrap">
        <v-card class="p-dashboard-statistics__statistics-card is-1" :loading="!guildStatistics">
          <v-card-title>先月</v-card-title>
          <v-card-subtitle v-if="lastMonthTotalCount" class="text-discord-primary">
            <div class="text-h4">
              {{ tweenedLastMonthTotalCount.number.toFixed(0).toLocaleString() }} <span class="text-caption">文字</span>
            </div>
          </v-card-subtitle>
          <v-card-subtitle v-else class="text-discord-primary">
            <div class="text-h4">---- <span class="text-caption">文字</span></div>
          </v-card-subtitle>
        </v-card>
        <v-card class="p-dashboard-statistics__statistics-card is-1" :loading="!guildStatistics">
          <v-card-title>今月</v-card-title>
          <v-card-subtitle v-if="guildStatistics" class="text-discord-primary">
            <div class="text-h4">
              {{ tweenedCurrentTotalCount.number.toFixed(0).toLocaleString() }} <span class="text-caption">文字</span>
            </div>
          </v-card-subtitle>
          <v-card-subtitle v-else class="text-discord-primary">
            <div class="text-h4">---- <span class="text-caption">文字</span></div>
          </v-card-subtitle>
        </v-card>
      </div>
    </div>

    <div v-if="vGuild.boosters.length === 0" class="text-h5 mx-auto">
      サーバーをブーストすると詳細な統計を確認できます
    </div>
    <template v-else>
      <!-- <div class="p-dashboard-statistics__statistics-container">
        <div class="text-h5 mx-auto mb-4">読み上げ文字数（月別）</div>
        <v-card
          class="p-dashboard-statistics__statistics-card is-3"
          :loading="!guildStatistics"
          :height="(mobile ? displayWidth - 16 : 836) / (mobile ? 1.5 : 1.8)"
        >
          <v-card-text>
            <Line id="guild-monthly-chart" :options="LineOptions" :data="createLineData(monthlyCharactors)">
              読込中...
            </Line>
          </v-card-text>
        </v-card>
      </div> -->

      <div class="p-dashboard-statistics__statistics-container">
        <div class="text-h5 mx-auto mb-4">今月の統計</div>
        <div class="d-flex justify-space-between gap-1 flex-wrap w-100">
          <div :class="{ 'd-flex flex-wrap gap-2': mobile }">
            <v-card
              class="p-dashboard-statistics__statistics-card is-1"
              :loading="!guildStatistics"
              :height="mobile ? displayWidth / 2 - 32 : 101"
            >
              <v-card-title>サーバー全体</v-card-title>
              <v-card-subtitle class="flex-grow-1 d-flex justify-center align-center text-h4 text-discord-primary">
                <div v-if="currentTotalCount">
                  {{ tweenedCurrentTotalCount.number.toFixed(0).toLocaleString() }}
                  <span class="text-caption">文字</span>
                </div>
                <div v-else>---- <span class="text-caption">文字</span></div>
              </v-card-subtitle>
            </v-card>
            <v-card
              class="p-dashboard-statistics__statistics-card is-1"
              :loading="!guildStatistics"
              :height="mobile ? displayWidth / 2 - 32 : 312"
            >
              <v-card-title>キャラクター別</v-card-title>
              <v-card-subtitle class="d-flex align-center flex-column">
                <Pie
                  id="current-charactor-chart"
                  :options="PieOptions"
                  :data="createPieData(currentCharactors)"
                  :width="mobile ? displayWidth / 2 - 92 : 244"
                  :height="mobile ? displayWidth / 2 - 92 : 244"
                >
                  読込中...
                </Pie>
              </v-card-subtitle>
            </v-card>
          </div>
          <v-card
            class="p-dashboard-statistics__statistics-card"
            :loading="!guildStatistics"
            :height="Math.max(lastMonthMembers.length * 25 + 64, mobile ? 0 : 429)"
          >
            <v-card-title>ユーザー別（最大30人）</v-card-title>
            <v-card-subtitle class="flex-grow-1">
              <Bar
                v-if="currentMembers.length"
                id="current-member-chart"
                :options="BarOptions"
                :data="createBarData(currentMembers)"
              >
                読込中...
              </Bar>
            </v-card-subtitle>
          </v-card>
        </div>
      </div>

      <div class="p-dashboard-statistics__statistics-container">
        <div class="text-h5 mx-auto mb-4">先月の統計</div>
        <div class="d-flex justify-space-between gap-1 flex-wrap w-100">
          <div :class="{ 'd-flex flex-wrap gap-2': mobile }">
            <v-card
              class="p-dashboard-statistics__statistics-card is-1"
              :loading="!guildStatistics"
              :height="mobile ? displayWidth / 2 - 32 : 101"
            >
              <v-card-title>サーバー全体</v-card-title>
              <v-card-subtitle class="flex-grow-1 d-flex justify-center align-center text-h4 text-discord-primary">
                <div v-if="lastMonthTotalCount">
                  {{ tweenedLastMonthTotalCount.number.toFixed(0).toLocaleString() }}
                  <span class="text-caption">文字</span>
                </div>
                <div v-else>---- <span class="text-caption">文字</span></div>
              </v-card-subtitle>
            </v-card>
            <v-card
              class="p-dashboard-statistics__statistics-card is-1"
              :loading="!guildStatistics"
              :height="mobile ? displayWidth / 2 - 32 : 312"
            >
              <v-card-title>キャラクター別</v-card-title>
              <v-card-subtitle class="d-flex align-center flex-column">
                <Pie
                  id="last-month-charactor-chart"
                  :options="PieOptions"
                  :data="createPieData(lastMonthCharactors)"
                  :width="mobile ? displayWidth / 2 - 92 : 244"
                  :height="mobile ? displayWidth / 2 - 92 : 244"
                >
                  読込中...
                </Pie>
              </v-card-subtitle>
            </v-card>
          </div>
          <v-card
            class="p-dashboard-statistics__statistics-card"
            :loading="!guildStatistics"
            :height="Math.max(lastMonthMembers.length * 25 + 64, mobile ? 0 : 429)"
          >
            <v-card-title>ユーザー別（最大30人）</v-card-title>
            <v-card-subtitle class="flex-grow-1">
              <Bar id="last-month-member-chart" :options="BarOptions" :data="createBarData(lastMonthMembers)">
                読込中...
              </Bar>
            </v-card-subtitle>
          </v-card>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computedAsync } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { Bar, Pie } from "vue-chartjs";
import { computed, onMounted, reactive, watch } from "vue";
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  PieController,
  ArcElement,
  BarController,
  BarElement,
} from "chart.js";
import { useDisplay } from "vuetify/lib/framework.mjs";
import gsap from "gsap";
import { useAPI } from "@/composable/useAPI";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

interface ElasticProps {
  key: string;
  name?: string;
  total_converted_text_len: { value: number };
}

const { mobile, width: displayWidth } = useDisplay();

Chart.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  BarController,
  PieController,
);

const PieOptions = {
  responsive: false,
  cutout: "20%",
  plugins: {
    legend: {
      display: false,
    },
  },
};
const BarOptions = {
  scales: {
    x: { ticks: { color: "#cdcdcd" } },
    y: { ticks: { color: "#cdcdcd" } },
  },
  indexAxis: "y" as const,
  plugins: {
    legend: {
      display: false,
    },
  },
};
// const LineOptions = {
//   maintainAspectRatio: false,
//   scales: {
//     x: { grid: { color: "#37393e" }, ticks: { color: "#dfdfdf" } },
//     y: { grid: { color: "#37393e" }, ticks: { color: "#dfdfdf" } },
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//   },
// };

const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const loadingStore = useLoadingStore();
const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

const guildStatistics = computedAsync<Record<string, ElasticProps[]>>(async () => {
  return !guild.value ? null : (await useAPI({ timeout: 30000 }).post(`api/statistics/${guild.value.id}`)).data;
});
const currentCharactors = computed(() => {
  if (!guildStatistics.value) return [];

  return [...guildStatistics.value.current_charactor_text_lengths].sort((x, y) => {
    return y.total_converted_text_len.value - x.total_converted_text_len.value;
  });
});
const currentTotalCount = computed(() =>
  Object.values(currentCharactors.value).reduce((sum, value) => sum + value.total_converted_text_len.value, 0),
);
const currentMembers = computed(() => {
  if (!guildStatistics.value) return [];

  return [...guildStatistics.value.current_user_text_lengths]
    .sort((x, y) => {
      return y.total_converted_text_len.value - x.total_converted_text_len.value;
    })
    .slice(0, 30);
});

const lastMonthCharactors = computed(() => {
  if (!guildStatistics.value) return [];

  return [...guildStatistics.value.last_month_charactor_text_lengths].sort((x, y) => {
    return y.total_converted_text_len.value - x.total_converted_text_len.value;
  });
});
const lastMonthTotalCount = computed(() =>
  Object.values(lastMonthCharactors.value).reduce((sum, value) => sum + value.total_converted_text_len.value, 0),
);
const lastMonthMembers = computed(() => {
  if (!guildStatistics.value) return [];

  return [...guildStatistics.value.last_month_user_text_lengths]
    .sort((x, y) => {
      return y.total_converted_text_len.value - x.total_converted_text_len.value;
    })
    .slice(0, 30);
});

// const monthlyCharactors = computed(() => {
//   if (!guildStatistics.value) return [];

//   return [...guildStatistics.value.monthly].sort((x, y) => {
//     return Number(x.key) - Number(y.key);
//   });
// });

const createColors = (columnSize: number): Array<string> => {
  const colors = Array<string>();
  for (const i of [...Array(columnSize).keys()]) {
    const division = (360 / columnSize) * i;
    colors[i] = `hsla(${division},100%,70%,100%)`;
  }
  return colors;
};

const createPieData = (charactors: ElasticProps[]) => ({
  labels: charactors.map((value) => value.key),
  datasets: [
    {
      backgroundColor: createColors(charactors.length),
      data: charactors.map((value) => value.total_converted_text_len.value),
      borderWidth: 1,
    },
  ],
});

const memberName = (name: string | undefined) => {
  if (!name) return "不明なユーザー";

  return name.length < 16 ? name : `${name.slice(0, 13)}...`;
};
const createBarData = (members: ElasticProps[]) => ({
  labels: members.map((value) => memberName(value.name)),
  datasets: [
    {
      backgroundColor: createColors(members.length),
      data: members.map((value) => value.total_converted_text_len.value),
      borderWidth: 1,
    },
  ],
});

// const yyyymm = (date: Date): string => {
//   return `${date.getFullYear()}年${date.getMonth() + 1}月`;
// };
// const createLineData = (monthly: ElasticProps[]) => ({
//   labels: monthly.map((value) => yyyymm(new Date(value.key))),
//   datasets: [
//     {
//       borderColor: "#5865F2",
//       backgroundColor: "#FFFFFFFF",
//       data: monthly.map((value) => value.total_converted_text_len.value),
//       borderWidth: 2,
//       pointRadius: 3,
//       pointHoverRadius: 9,
//       pointHitRadius: 9,
//       pointBackgroundColor: "#884684",
//       pointBorderColor: "#884684",
//       hoverBackgroundColor: "#884684",
//     },
//   ],
// });

onMounted(() => {
  loadingStore.setIsGuildChangeLoading(false);
});

const tweenedLastMonthTotalCount = reactive({
  number: 0,
});
watch(lastMonthTotalCount, (n) => {
  gsap.to(tweenedLastMonthTotalCount, { duration: 1, number: Number(n) || 0 });
});

const tweenedCurrentTotalCount = reactive({
  number: 0,
});
watch(currentTotalCount, (n) => {
  gsap.to(tweenedCurrentTotalCount, { duration: 1, number: Number(n) || 0 });
});
</script>

<style lang="scss">
.p-dashboard-statistics__statistics-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 48px;
  width: 900px;

  .is-mobile & {
    width: 100%;
  }
}

.p-dashboard-statistics__statistics-card {
  display: flex;
  justify-content: start;
  flex-flow: column;
  text-align: center;
  width: 600px;
  padding: 8px;
  margin-bottom: 16px;

  &.is-1 {
    width: calc(300px - 12px);
  }

  &.is-2 {
    width: calc(600px - 16px);
  }

  &.is-3 {
    width: calc(900px - 16px);
  }

  .is-mobile & {
    width: calc(100vw - 16px);
  }

  .is-mobile &.is-1 {
    width: calc(50vw - 12px);
  }
}
</style>
