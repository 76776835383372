<template>
  <v-navigation-drawer
    v-if="!mobile"
    :model-value="drawer"
    width="320"
    class="l-dashboard__guilds"
    disable-route-watcher
    @update:model-value="userStore.toggleDrawer()"
  >
    <div class="d-flex h-100 pa-2 pl-0">
      <DashboardLayoutDrawerGuilds />
      <v-list class="l-dashboard__menus flex-grow-1" bg-color="surface-lighten-1">
        <template v-if="guild">
          <div class="l-dashboard__guild-name">
            {{ guild?.name || "・・・" }}
          </div>
          <v-divider class="my-2" color="black" thickness="2" />
        </template>
        <v-list-item
          v-for="menu in menus"
          :key="menu.title"
          :title="menu.title"
          :base-color="menu.color"
          :prepend-icon="menu.icon"
          rounded
          :active="menu.to === $route.path"
          @click="onClick(menu.to)"
        />
        <template v-if="vGuildStore.isSubscribed">
          <v-divider class="my-2" color="black" thickness="2" />
          <div class="mb-2">ブーストメンバー({{ vGuild.boosters.length }})</div>
          <VcBoosterList :boosters="vGuild.boosters" />
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import { useUserStore } from "@/stores/useUserStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useMenuStore } from "@/stores/useMenuStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const { mobile } = useDisplay();
const router = useRouter();
const route = useRoute();

const userStore = useUserStore();
const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const menuStore = useMenuStore();
const loadingStore = useLoadingStore();

const { drawer } = storeToRefs(userStore);
const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);
const { menus } = storeToRefs(menuStore);

const setDrawer = () => {
  userStore.setDrawer(!mobile.value);
};
watch(route, setDrawer);
watch(mobile, setDrawer);
setDrawer();

const onClick = (to: string) => {
  if (to === route.path) return;

  const notLoadingPathes = ["/payments", "/boost", "/dashboard", "bots"];
  if (notLoadingPathes.every((path) => !to.includes(path))) {
    loadingStore.setIsGuildChangeLoading(true);
  }

  nextTick(() => {
    // vGuildStore.setVGuild();
    router.push(to);
  });
};
</script>

<style lang="scss">
.l-dashboard__menus.v-navigation-drawer {
  .v-list {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.l-dashboard__guild-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font-weight: bold;
  padding: 0 8px 8px;
}

.l-dashboard__menus {
  padding: 20px 6px;
  width: 240px;
}

.l-dashboard__booster-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 163px;
}
</style>
