<template>
  <v-dialog v-model="dialogWrap" width="600">
    <template #activator="{ props: dialogProps }">
      <slot :props="dialogProps" />
    </template>
    <template #default="{ isActive }">
      <v-card color="background" title="ブーストのサブスクリプション">
        <v-card-text>
          <p class="my-6">ブーストでVOISCORDをアップグレードし、コミュニティの盛り上がりに貢献しよう。</p>
          <div class="d-flex align-center">
            <v-btn-group class="c-boost-dialog__boost-count-group">
              <v-btn
                icon="mdi-minus"
                size="small"
                color="background-lighten-1"
                :class="{ 'cursor-not-allowed opacity-40': boostCount === 1 }"
                @click="minusBoost"
              />
              <v-text-field
                v-model="boostCount"
                class="c-boost-dialog__boost-count"
                variant="solo"
                hide-details
                @input="onInputBoost"
              />
              <v-btn
                icon="mdi-plus"
                size="small"
                color="background-lighten-1"
                :class="{ 'cursor-not-allowed opacity-40': boostCount === MAX_BOOST_COUNT }"
                @click="plusBoost"
              />
            </v-btn-group>
            <v-icon class="ml-2" color="boost">mdi-lightning-bolt</v-icon>
            <b class="text-boost">ブースト</b>
            <v-spacer />
            <p class="text-grey-lighten-1">各 ¥200</p>
            <div class="text-h6 text-grey-lighten-1">／</div>
            <p class="text-grey-lighten-1">月</p>
          </div>
          <v-divider class="my-6" />
          <div class="d-flex align-end mb-6">
            <b>合計</b>
            <v-spacer />
            <p class="text-grey-lighten-1 mr-1">月額</p>
            <b class="text-h5 font-weight-bold">¥{{ sum }}</b>
          </div>
          <div v-if="paymentMethods.length === 0" class="text-boost">
            購入する前に
            <router-link to="/boost" class="c-boost-dialog__link-text">[マイページ > ブーストを管理]</router-link>
            から支払方法を登録してください。
          </div>
          <div v-else>
            <v-select
              v-model="paymentMethod"
              label="支払方法の選択"
              :items="paymentMethods"
              :item-value="(item) => item"
              :item-title="() => ''"
              class="payment-method-selection"
              aria-required="true"
            >
              <template #selection="{ item }">
                <v-list-item class="pa-4">
                  <VcCard :payment-method="item.value" show-default />
                </v-list-item>
              </template>
              <template #item="{ props: itemProps, item }">
                <v-list-item v-bind="itemProps">
                  <VcCard :payment-method="item.value" show-default />
                </v-list-item>
              </template>
            </v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="plain" width="80" text="やめておく" @click="isActive.value = false" />
          <v-btn
            variant="elevated"
            text="サブスクリプションする！"
            color="discord-primary"
            :disabled="!paymentMethod || acceptSubscriptionLock"
            @click="acceptSubscription"
          />
        </v-card-actions>
        <v-overlay :model-value="isLoading" class="align-center justify-center" persistent contained>
          <v-progress-circular color="discord-primary" indeterminate size="64" />
        </v-overlay>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import type { User, PaymentMethod } from "@/types";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted, watch } from "vue";
import { useAPI } from "@/composable/useAPI";
import { useUserStore } from "@/stores/useUserStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { usePaymentMethodStore } from "@/stores/usePaymentMethodStore";

const props = defineProps<{
  guildId: string;
}>();

const emit = defineEmits<{
  (e: "update", boosters: User[]): void;
}>();

const userStore = useUserStore();
const snackbarStore = useSnackbarStore();
const paymentMethodStore = usePaymentMethodStore();

const { user } = storeToRefs(userStore);
const { paymentMethods } = storeToRefs(paymentMethodStore);

const BOOST_AMOUNT_PER_MONTH = 200;
const MIN_BOOST_COUNT = 1;
const MAX_BOOST_COUNT = 10;

const dialog = ref(false);
const boostCount = ref(1);
const isLoading = ref(false);
const paymentMethod = ref<PaymentMethod>();
const acceptSubscriptionLock = ref(false);

const dialogWrap = computed({
  get() {
    return dialog.value;
  },
  set(newValue: boolean) {
    if (dialog.value === newValue) return;

    dialog.value = newValue;
    if (!dialog.value) {
      isLoading.value = false;
      acceptSubscriptionLock.value = false;
    }
  },
});

const clamp = (value: number) => {
  return Math.min(Math.max(value, MIN_BOOST_COUNT), MAX_BOOST_COUNT);
};
const onInputBoost = () => {
  boostCount.value = clamp(boostCount.value);
};
const minusBoost = () => (boostCount.value = clamp(boostCount.value - 1));
const plusBoost = () => {
  boostCount.value = clamp(boostCount.value + 1);
};
const sum = computed(() => {
  return (BOOST_AMOUNT_PER_MONTH * boostCount.value).toLocaleString();
});
const acceptSubscription = async () => {
  if (!paymentMethod.value) return;

  isLoading.value = true;
  useAPI({ timeout: 30000 })
    .post(`/api/v_guilds/${props.guildId}/boosts`, {
      boost_count: boostCount.value,
      payment_method_id: paymentMethod.value.id,
    })
    .then(({ data }) => {
      emit("update", data.boosters);
      dialogWrap.value = false;
    })
    .catch((error) => {
      if (error.response?.status === 400) {
        error.response.data.errors.forEach((e: { error_message: string }) => {
          const text = e.error_message.includes("AWAITING_CUSTOMER_ACTION")
            ? "支払方法はご利用者様から認可されていません。該当の支払方法を削除し、再度登録し直していただくことで解決する場合がございます。"
            : e.error_message;
          snackbarStore.addTopRight({
            color: "danger",
            text,
            timeout: -1,
          });
        });
        dialogWrap.value = false;
        return;
      }

      snackbarStore.addTopRight({
        color: "danger",
        text: "サーバーとの通信に失敗しました。サブスクリプション状況を確認するためには画面を更新してください。",
        timeout: -1,
      });
      acceptSubscriptionLock.value = true;
      isLoading.value = false;
    });
};

const setDefaultPaymentMethod = () => {
  if (paymentMethods.value.length > 0) {
    paymentMethod.value = paymentMethods.value.find((paymentMethod) => {
      return paymentMethod.default_flag;
    });
  }
};

watch(paymentMethods, () => {
  setDefaultPaymentMethod();
});

onMounted(async () => {
  if (user?.value?.id) {
    await paymentMethodStore.fetch(user.value.id);
    setDefaultPaymentMethod();
  }
});
</script>

<style lang="scss">
.c-boost-dialog__boost-count-group {
  &.v-btn-group--density-default.v-btn-group {
    height: 32px;
  }
}

.c-boost-dialog__boost-count {
  min-width: 48px;
  width: 48px;

  .v-field {
    border-radius: 0;
  }

  .v-field__input {
    padding: 0;
    text-align: center;
    min-height: 32px;
    width: 48px;
  }
}

.payment-method-selection {
  .v-field__input {
    padding: 16px 0px 0px 2px;
  }
}
</style>
