import type { PaymentMethod } from "types";

export const paymentMethodIcon = (paymentMethod: PaymentMethod): string => {
  switch (paymentMethod.brand) {
    case "VISA":
      return "visa.svg";
    case "MASTER":
      return "mastercard.svg";
    case "JCB":
      return "jcb.svg";
    case "AMEX":
      return "amex.svg";
    case "DINERS":
      return "diners.svg";
    case "DISCOVER":
      return "discover.svg";
    default:
      return "code-front.svg";
  }
};

export const formatCardNumber = (card: PaymentMethod, mobile: boolean = false): string => {
  if (mobile) return `*** ${(card.card_no || "").slice(-4)}`;

  switch (card.brand) {
    case "VISA":
    case "MASTER":
    case "JCB":
    case "DISCOVER":
      return card.card_no.match(/.{1,4}/g)!.join(" ");
    case "AMEX":
      return card.card_no.slice(0, 6) + " " + card.card_no.slice(6, 11);
    case "DINERS":
      return card.card_no.slice(0, 4) + " " + card.card_no.slice(4, 10) + " " + card.card_no.slice(10, 14);
    default:
      return card.card_no;
  }
};

export const formatDate = (dateOrStr: string | Date) => {
  const intlNumberFormat = new Intl.NumberFormat("ja-JP", { minimumIntegerDigits: 2 });
  const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr;
  return `${date.getFullYear()}年${intlNumberFormat.format(date.getMonth() + 1)}月${intlNumberFormat.format(date.getDate())}日`;
};
