<template>
  <VcSettingBase :only-subscribed="onlySubscribed" :label="label">
    <template #pc>
      <v-card class="vc-setting" flat>
        <v-card-text>
          <div class="d-flex align-center">
            <v-icon v-if="onlySubscribed" class="mr-1" icon="mdi-lightning-bolt" color="boost" />
            <v-label class="vc-setting__label mr-auto" :text="label" />
            <v-switch
              v-model="value"
              class="vc-setting-switch__switch"
              :class="{ 'text-inactive': !value }"
              hide-details
              inset
              :color="color"
              :disabled="disabled"
            />
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template #display>
      <v-switch
        v-model="value"
        class="vc-setting-switch__switch my-n3"
        :class="{ 'text-inactive': !value }"
        hide-details
        inset
        :color="color"
        :disabled="disabled"
      />
    </template>
  </VcSettingBase>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useVGuildStore } from "@/stores/useVGuildStore";

const props = defineProps<{
  modelValue: boolean;
  label: string;
  onlySubscribed?: boolean;
  requiredBoostCount?: number;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: boolean): void;
}>();

const vGuildStore = useVGuildStore();
const disabled = computed(() => {
  return props.disabled || (props.requiredBoostCount && props.requiredBoostCount > vGuildStore.boostCount) || false;
});

const value = computed({
  get(): boolean {
    return disabled.value ? false : props.modelValue;
  },
  set(newValue: boolean) {
    emit("update:modelValue", newValue);
  },
});
const color = computed(() => {
  return disabled.value ? "disabled" : "primary";
});
</script>

<style lang="scss" scoped>
.vc-setting-switch__switch {
  flex: 0 1 auto;
}
</style>
