<template>
  <VcSettingBase :only-subscribed="false" :label="label">
    <template #pc>
      <v-card class="vc-setting" flat>
        <v-card-text>
          <div class="d-flex">
            <v-label class="vc-setting__label mr-auto" :text="label" />
            <v-select
              v-model="value"
              :items="items"
              :style="{ maxWidth: `${width}px` }"
              density="compact"
              hide-details
              variant="outlined"
              :disabled="disabled"
            />
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template #display>
      <v-select
        v-model="value"
        :items="items"
        :style="{ maxWidth: `${width}px` }"
        density="compact"
        hide-details
        variant="outlined"
        :disabled="disabled"
      />
    </template>
  </VcSettingBase>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  modelValue: string;
  label: string;
  width: number | string;
  items: string[];
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: string): void;
}>();

const value = computed({
  get(): string {
    return props.modelValue;
  },
  set(newValue: string) {
    emit("update:modelValue", newValue);
  },
});
</script>
