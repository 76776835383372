<template>
  <v-overlay :model-value="isLoading" class="align-center justify-center" persistent contained>
    <v-progress-circular color="discord-primary" indeterminate size="64" />
  </v-overlay>
  <v-dialog id="vc-card-dialog" v-model="dialogWrap" width="500">
    <template #activator="{ props: dialogProps }">
      <slot :props="dialogProps" />
    </template>
    <template #default="{ isActive }">
      <v-card color="background" title="支払方法の登録">
        <v-card-text>
          <div>
            <div class="d-flex align-center">
              <v-text-field
                v-model="email"
                label="メールアドレス"
                type="email"
                required
                :rules="emailRules"
                :disabled="isLoading"
              />
            </div>
            <v-alert class="text-white" color="warning" icon="mdi-information" variant="tonal">
              画面が変わらない場合は、ブラウザのポップアップブロックの解除をお試しください。<br />
              <br />
              iOSの場合は
              <a
                class="text-info"
                href="https://support.apple.com/ja-jp/guide/iphone/ipha49a83ae8/ios"
                target="_blank"
                rel="noopener noreferrer"
              >
                iPhoneのSafariでポップアップをブロックする | Appleサポート
              </a>
              もご確認ください。
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="plain" width="80" text="やめておく" @click="isActive.value = false" />
          <v-btn
            variant="elevated"
            :loading="isLoading"
            text="カードを登録する"
            color="discord-primary"
            :disabled="!isValidEmail"
            @click="register"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useUserStore } from "@/stores/useUserStore";
import { usePaymentMethodStore } from "@/stores/usePaymentMethodStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const { siteUrl } = useRuntimeConfig();

const snackbarStore = useSnackbarStore();
const paymentMethodStore = usePaymentMethodStore();

const email = ref("");
const isValidEmail = ref(false);
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const dialog = ref(false);
const isLoading = ref(false);

const emailRules = [
  (value: string) => {
    if (!value) {
      isValidEmail.value = false;
      return "メールアドレスを入力してください";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      isValidEmail.value = false;
      return "メールアドレスの形式が正しくありません";
    }
    isValidEmail.value = true;
    return true;
  },
];

const dialogWrap = computed({
  get() {
    return dialog.value;
  },
  set(newValue: boolean) {
    if (dialog.value === newValue) return;

    dialog.value = newValue;
    if (!dialog.value) {
      isLoading.value = false;
      email.value = "";
    }
  },
});

let handleMessageActive = false;
const handleMessage = async (event: { data: string }) => {
  if (window.origin !== siteUrl) return;
  if (!user?.value?.id) return;

  if (event.data === "success") {
    await paymentMethodStore.fetch(user.value.id);
    snackbarStore.addTopRight({ color: "success", text: "支払方法を追加しました" });
    dialogWrap.value = false;
    window.removeEventListener("message", handleMessage);
    handleMessageActive = false;
  } else if (event.data === "cancel") {
    snackbarStore.addTopRight({ color: "cancel", text: "キャンセルしました" });
    dialogWrap.value = false;
    window.removeEventListener("message", handleMessage);
    handleMessageActive = false;
  } else {
    console.error("unexpected message received");
  }
};
const register = async () => {
  if (!isValidEmail.value) return;

  isLoading.value = true;
  try {
    const response = await useAPI().post(`/api/v_users/${user?.value?.id}/payment_methods`, { email: email.value });
    if (!handleMessageActive) {
      window.addEventListener("message", handleMessage);
      handleMessageActive = true;
    }
    window.open(response.data.redirect_url, "_blank", "popup,width=540px,height=540px");
  } catch (e) {
    snackbarStore.addError(e);
    isLoading.value = false;
  }
};
</script>

<style lang="scss">
.form-area__email-form {
  background-color: #ffffff30;
  color: white;
  width: 100%;
  padding: 8px;
  border: 1px solid black;
  border-radius: 8px;
}

.c-boost-dialog__boost-count-group {
  &.v-btn-group--density-default.v-btn-group {
    height: 32px;
  }
}

.c-boost-dialog__boost-count {
  min-width: 48px;
  width: 48px;

  .v-field {
    border-radius: 0;
  }

  .v-field__input {
    padding: 0;
    text-align: center;
    min-height: 32px;
    width: 48px;
  }
}
</style>
