<template>
  <VcGuildInvite v-if="guild?.status === 'managed_not_joined'" />
  <div v-if="showSettings && vGuild.id !== '0'" class="pa-2">
    <!-- <v-card
      v-if="mobile"
      class="mb-4 mx-auto"
      color="boost"
      :width="mobile ? 'auto' : '800'"
      @click="router.push(`/server/${guild?.id}/boost`)"
    >
      <v-card-title>
        ブーストメンバー
        <v-icon icon="mdi-arrow-right-bold-circle" size="large" />
      </v-card-title>
      <div class="d-flex gap-2 ma-3">
        <div v-if="vGuild.boosters.length === 0">サーバーをブーストして、特典をアンロックしよう！</div>
        <template v-else>
          <span v-for="booster in vGuild.boosters" :key="booster.id" class="relative">
            <v-avatar color="black" size="48">
              <v-img :src="userIcon(booster)" width="48" />
            </v-avatar>
            <v-chip
              variant="elevated"
              class="p-dashboard__boosted-guild-boost text-boost"
              density="compact"
              size="small"
              color="white"
            >
              <v-icon icon="mdi-lightning-bolt" /> x {{ booster.boostCount }}
            </v-chip>
          </span>
        </template>
      </div>
    </v-card> -->
    <VcSettingSelect v-model="speaker" label="話者" width="260" :items="['結月ゆかり', '琴葉葵', '紲星あかり']" />
    <VcSettingNumber id="volume" v-model="volume" percent :initial-value="100" :min="0" :max="200" label="音量" />
    <!-- <VcSettingNumber
      v-model="speakingVolumePercentage"
      percent
      :initial-value="100"
      :min="0"
      :max="200"
      label="他ユーザーが話中の音量"
    /> -->
    <VcSettingNumber
      id="speed"
      v-model="speed"
      percent
      :initial-value="100"
      :min="80"
      :max="160"
      label="スピード"
      only-subscribed
      :required-boost-count="1"
    />
    <VcSettingNumber
      id="pitch"
      v-model="pitch"
      percent
      :initial-value="100"
      :min="80"
      :max="160"
      label="ピッチ"
      only-subscribed
      :required-boost-count="1"
    />
    <VcSettingNumber
      id="max-message-len"
      v-model="maxMessageLen"
      :initial-value="40"
      :min="20"
      :max="vGuildStore.boostCount === 0 ? 60 : 140"
      label="読み上げ文字数制限"
    />
    <VcSettingNumber
      id="min-number-len-reading-as-digits"
      v-model="minNumberLenReadingAsDigits"
      :initial-value="0"
      :min="0"
      :max="10"
      label="数値を一桁ずつ読み上げる最小文字数"
    />
    <VcSettingSwitch v-model="callByNickname" label="ニックネームを使用" />
    <VcSettingSwitch v-model="readBotMessage" label="BOTを読み上げる" only-subscribed :required-boost-count="1" />
    <VcSettingSwitch v-model="onlyVoiceMembers" label="VC接続中のユーザーのみ読み上げる" />
    <VcSettingSwitch v-model="userCostomization" label="ユーザー設定を許可" />
    <VcSettingSwitch v-model="includeCommandChannel" label="join実行チャンネルを読み上げる" />
    <VcSettingFormatBase
      v-model="messageFormat"
      label="メッセージの読み上げ設定"
      use-auto-save-section
      dynamic-flg-label="発言者の名前を読み上げる"
      :dynamic-format-on="'{{name}} {{message}}'"
      :dynamic-format-off="'{{message}}'"
      :dynamic-format-regexp="/^\{\{name\}\}.*\{\{message\}\}/"
      :basic-variables="['name', 'message', 'channel', 'time']"
    >
      <div class="d-flex align-center">
        <v-label class="vc-setting__label ml-4 mr-auto" text="連続投稿時の名前の省略" />
        <v-select
          v-model="readNameSkipIntervalType"
          :items="readNameSkipIntervalTypes"
          :style="{ 'max-width': '140px' }"
          density="compact"
          hide-details
          variant="outlined"
          item-title="label"
          item-value="value"
        />
      </div>
    </VcSettingFormatBase>
    <VcSettingFormatBase
      v-model="joinAnnounceFormat"
      label="VC参加時の読み上げ設定"
      dynamic-flg-label="VC参加者を読み上げる"
      :dynamic-format-on="'{{name}}が参加しました'"
      :dynamic-format-off="''"
      :dynamic-format-regexp="/^\{\{name\}\}/"
      :basic-variables="['name', 'channel', 'time']"
    />
    <VcSettingFormatBase
      v-model="leaveAnnounceFormat"
      label="VC退室時の読み上げ設定"
      dynamic-flg-label="VC退室者を読み上げる"
      :dynamic-format-on="'{{name}}が退室しました'"
      :dynamic-format-off="''"
      :dynamic-format-regexp="/^\{\{name\}\}/"
      :basic-variables="['name', 'channel', 'time']"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useAsyncUpdaterStore } from "@/stores/useAsyncUpdaterStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { ReadNameSkipIntervalType } from "@/const";

const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const loadingStore = useLoadingStore();

const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

const showSettings = computed(() => {
  return !guildStore.managedNotJoinedGuilds.map((guild) => guild.id).includes(vGuild.value.guild_id);
});

const propertyUpdater = useAsyncUpdaterStore().createPropertyUpdater(vGuild, (params, onAfterUpdate) => async () => {
  return useAPI()
    .patch(`/api/v_guilds/${vGuild.value.guild_id}`, params)
    .then((response) => {
      useSnackbarStore().addTopRight({ color: "success", text: "保存しました" });
      onAfterUpdate(() => vGuildStore.$patch({ vGuild: response.data }))();
    });
});

const speaker = propertyUpdater("speaker");
const volume = propertyUpdater("volume");
// const speakingVolumePercentage = propertyUpdater("speaking_volume_percentage");
const speed = propertyUpdater("speed");
const pitch = propertyUpdater("pitch");
const maxMessageLen = propertyUpdater("max_message_len");
const minNumberLenReadingAsDigits = propertyUpdater("min_number_len_reading_as_digits");
const callByNickname = propertyUpdater("call_by_nickname");
const readBotMessage = propertyUpdater("read_bot_message");
const onlyVoiceMembers = propertyUpdater("only_voice_members");
const userCostomization = propertyUpdater("user_customization");
const includeCommandChannel = propertyUpdater("include_command_channel");
const messageFormat = propertyUpdater("message_format");
const joinAnnounceFormat = propertyUpdater("join_announce_format");
const leaveAnnounceFormat = propertyUpdater("leave_announce_format");
const readNameSkipIntervalType = propertyUpdater("read_name_skip_interval_type");

const readNameSkipIntervalTypes = [
  { label: "しない", value: ReadNameSkipIntervalType.ZERO },
  { label: "10秒間", value: ReadNameSkipIntervalType.TEN_SECONDS },
  { label: "30秒間", value: ReadNameSkipIntervalType.THIRTY_SECONDS },
  { label: "5分間", value: ReadNameSkipIntervalType.FIVE_MINUTES },
  { label: "常に省略", value: ReadNameSkipIntervalType.INFINITY },
];

watch(vGuild, () => {
  loadingStore.setIsGuildChangeLoading(false);
});
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});
</script>
